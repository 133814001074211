import React, { useEffect, useRef, useCallback, useState, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Form, Popover, Row, Tooltip } from "antd";
import { FilePdfOutlined, FileOutlined, LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, DeleteFilled } from "@ant-design/icons";
import AppButton from "components/AppButton/AppButton";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import ChatAvatar from "./components/ChatAvatar";
import SenderMenu from "components/GatewayMessage/MessageBox/SenderMenu";
import MessageContainer from "components/GatewayMessage/MessageContainer";
import { imageSupportList, videoSupportList, audioSupportList } from "common/utils/fileSupportList";
import { dateTimeFormatWithSeconds, sharedNumber } from "constant/constant";
import AlertModal from "components/Modals/AlertModal";
import { checkSender, getParsedContact } from "constant/messageConstants";
import { checkIsEnglish, compileMessageForLength, caculateMessageLengthWithLineBreaks, checkTotalMessageCount } from "common/utils/messageUtilities";
import styles from "./chat.module.scss";
import ViewMedia from "components/ContentDrawer/ViewMediaModal";
import MessageType from "components/GatewayMessage/MessageType";

const MessageSchema = Yup.object().shape({
  message: Yup.string().required("Enter a message before sending"),
  sender: Yup.string()
    .max(11, "no longer then 11 characters")
    .required("Select a sender")
});

function Chat(props) {
  const {
    key,
    showHeader = false,
    modalStore,
    authStore,
    contactName,
    contactNumber,
    messageList,
    currentMessageContent,
    showMessageBox,
    handleSend,
    senderId,
    chatBoxClass,
    phoneFrameImageUrl,
    messageStore,
    isMms,
    smallSize = false,
    integrationStore,
    squareSpaceBtn = false,
    onRefresh,
    isMobile,
    handleHideChat
  } = props;

  const history = useHistory();
  const location = useLocation();
  const chatEndRefElement = useRef();
  const formikRef = useRef();
  const chatScrollRef = useRef();
  const [loading, setLoading] = useState(false);
  const [scrollHeight, setScroll] = useState([]);
  const [searchedContact, setSearchedContact] = useState();
  const [lowBalanceAlert, setLowBalanceAlert] = useState(false);
  const [isSearchingContact, setIsSearchingContact] = useState(false);
  const [viewMedia, setViewMedia] = useState(false);

  const { loadMore, getTotal } = messageStore;
  const { user, dedicatedNumList } = authStore;
  const { invoiceLink, searchContact, contact, setInvoiceLink } = integrationStore;

  useEffect(() => {
    formikRef.current?.setFieldValue("message", "");
  }, []);

  useEffect(() => {
    if (isSearchingContact) return;
    setSearchedContact(contact?.email ? contact : undefined);
  }, [contact]);

  useEffect(() => {
    if (invoiceLink) {
      formikRef.current?.setFieldValue("message", formikRef.current.values?.message + "\n\n" + invoiceLink);
    }
  }, [invoiceLink]);

  useEffect(() => {
    if (!showMessageBox) return;
    formikRef.current?.resetForm();
    if (isMms) handleRemoveFile();
    if (senderId) {
      formikRef.current?.setFieldValue("sender", checkSender(senderId, user, messageStore));
    }
  }, [senderId, key]);

  useEffect(() => {
    if (!showMessageBox) return;
    if (isMms) {
      if (dedicatedNumList[isMms ? "mms" : "sms"].includes(formikRef.current?.values?.sender)) {
        formikRef.current?.setFieldValue("sender", formikRef.current?.values?.sender);
      } else {
        formikRef.current?.setFieldValue("sender", sharedNumber);
      }
    } else {
      if (senderId) {
        formikRef.current?.setFieldValue("sender", checkSender(senderId, user, messageStore));
      } else {
        formikRef.current?.setFieldValue("sender", sharedNumber);
      }
    }
  }, [isMms]);

  useEffect(() => {
    if (messageList?.length <= 20) {
      chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
      setScroll([]);
    } else {
      if (scrollHeight.length == 1) {
        chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight - scrollHeight[0];
      } else {
        chatScrollRef.current.scrollTop = scrollHeight[scrollHeight.length - 1] - scrollHeight[scrollHeight.length - 2];
      }
    }
  }, [messageList, currentMessageContent]);

  useEffect(() => {
    if (!showMessageBox) return;
    if (formikRef.current?.media) {
      messageStore?.setCurrentImage(formikRef.current?.media);
    } else {
      messageStore?.setCurrentImage(null);
    }
  }, [formikRef.current?.media]);

  // The scroll listener
  const handleScroll = useCallback(() => {
    if (chatScrollRef.current.scrollTop == 0 && getTotal() > messageList.length) {
      setLoading(true);
      loadMore().finally(res => {
        setLoading(false);
      });
      setScroll(old => [...old, chatScrollRef.current.scrollHeight]);
    }
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = chatScrollRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  function getStatusIcon(status) {
    let icon = "";
    switch (status) {
      case "delivered":
        icon = <i className="fas fa-check-double" />;
        break;
      case "sent":
        icon = <i className="fas fa-check" />;
        break;
      case "queued":
        icon = <i className="far fa-user-clock" />;
        break;
      case "scheduled":
        icon = <i className="far fa-user-clock" />;
        break;
      case "failed":
        icon = <i className="fa fa-times" />;
        break;
      case "blocked":
        icon = <i className="fas fa-ban" />;
        break;
      case "pending":
        icon = <i className="fas fa-clock" />;
        break;
      default:
        break;
    }
    return icon;
  }

  const initialValues = {
    message: "",
    sender: checkSender(senderId, user, messageStore) || sharedNumber
  };

  // for checking url type (blob OR File)
  const IsBlob = data => {
    if (data && typeof data === "object") {
      return URL.createObjectURL(data);
    } else {
      return data;
    }
  };

  const handleRemoveFile = () => {
    formikRef.current?.setFieldValue("media", null);
    formikRef.current?.setFieldValue("mediaName", null);
    formikRef.current?.setFieldValue("url", null);
    formikRef.current?.setFieldValue("subject", null);
    messageStore.setCurrentImage(null);
    messageStore.setPhoneSubject(null);
  };

  return (
    <Row gutter={[0, 0]} type="flex" className={`${chatBoxClass} position-relative`}>
      {showHeader && (
        <Col span={22} lg={23} className="pb-2">
          <div span={23} className="d-flex flex-row align-items-center gap-2">
            {isMobile && <i className="fas fa-arrow-left fs-5 text-info-color me-3" onClick={handleHideChat} type="button" />}
            <ChatAvatar contactNumber={contactNumber} contactName={contactName} />
            <h5 className="fw-900 m-0">{contactName || contactNumber}</h5>
            {squareSpaceBtn && (
              <AppButton
                light
                size="small"
                className="rouned-xl"
                disabled={isSearchingContact}
                label={
                  <Popover placement="top" autoAdjustOverflow trigger={["hover", "click"]} content={<span className="text-black">Search Contact</span>}>
                    <span>Search {isSearchingContact ? <LoadingOutlined /> : <i className="fas fa-info-circle align-middle" />}</span>
                  </Popover>
                }
                onClick={() => {
                  setIsSearchingContact(true);
                  setSearchedContact();
                  searchContact(getParsedContact(contactNumber))
                    .then(({ data }) => {
                      if (data?.email) {
                        setSearchedContact(data);
                      } else {
                        setSearchedContact({ error: "Contact Not Found" });
                      }
                    })
                    .finally(() => {
                      setIsSearchingContact(false);
                    });
                }}
              />
            )}
            {onRefresh && (
              <div className="text-center mx-3">
                <Tooltip title="Refresh">
                  <i className="fas fa-sync-alt text-info-color fs-5" type="button" onClick={onRefresh} />
                </Tooltip>
              </div>
            )}
          </div>
          {searchedContact && (
            <>
              <hr />
              <div className="p-2 text-muted fs-5 d-flex align-items-center">
                {searchedContact?.email ? (
                  <>
                    <CheckCircleOutlined className="text-success me-2" />{" "}
                    <span>
                      Contact Found <strong>{searchedContact?.email}</strong>
                    </span>
                  </>
                ) : (
                  <>
                    <CloseCircleOutlined className="text-danger me-2" /> <span>{searchedContact?.error}</span>
                  </>
                )}
              </div>
            </>
          )}
        </Col>
      )}
      {loading && (
        <div
          className="position-absolute start-50 translate-middle text-center bg-white shadow rounded-circle"
          style={{
            top: "72px",
            width: "28px",
            height: "28px"
          }}
        >
          <LoadingOutlined className="text-info-color" />
        </div>
      )}
      <Col span={24} className="flex-grow-1">
        <div className={`${styles.chatBox} ${showHeader ? styles.inbox : ""} pe-1`} ref={chatScrollRef}>
          {messageList &&
            messageList.map((msg, i) => {
              return (
                <div className={styles.msgText} key={i}>
                  <div className={msg.status === "received" ? styles.clientTextCover : styles.userTextCover} style={{ maxWidth: "420px" }}>
                    {/* for mms media */}
                    {msg?.isMMS &&
                      msg?.media &&
                      msg?.media.map((_, idx) => {
                        return (
                          <div className={`${styles.mmsImage} justify-content-center`} key={idx}>
                            <a href={_?.url} target="_blank">
                              {imageSupportList.includes(`image/${_?.url?.split(".").pop()}`) ? (
                                <img
                                  style={{
                                    width: "-webkit-fill-available",
                                    maxWidth: isMobile ? "205px" : "400px",
                                    objectFit: "contain"
                                  }}
                                  src={_?.url}
                                  // src={IsBlob(_?.url)}
                                  alt={"media"}
                                />
                              ) : videoSupportList.includes(`video/${_?.url?.split(".").pop()}`) ? (
                                <video
                                  style={{
                                    width: "-webkit-fill-available",
                                    maxWidth: isMobile ? "205px" : "400px",
                                    objectFit: "contain"
                                  }}
                                  src={_?.url}
                                  // src={IsBlob(_?.url)}
                                  controls
                                />
                              ) : audioSupportList.includes(`audio/${_?.url?.split(".").pop()}`) ? (
                                <audio
                                  style={{ maxWidth: "180px" }}
                                  controls
                                  src={_?.url}
                                  // src={IsBlob(_?.url)}
                                />
                              ) : ["txt", "xml", "smil"].includes(_?.url?.split(".").pop()) ? null : (
                                <div
                                  style={{
                                    maxWidth: "180px",
                                    background: "#149cbe30"
                                  }}
                                  className="rounded p-4"
                                >
                                  <FilePdfOutlined className="fs-1 text-info-color" />
                                </div>
                              )}
                            </a>
                          </div>
                        );
                      })}
                    {/* message text */}
                    <p className="pe-4 words-dont-break-out">{msg?.mediatxt ? msg?.mediatxt : msg.message}</p>
                    {/* status icons */}
                    <div className="text-end w-100" style={{ fontSize: "10px" }}>
                      {moment(msg.send_time).isSame(moment(), "day") ? moment(msg.send_time).format(dateTimeFormatWithSeconds) : moment(msg.send_time).format(dateTimeFormatWithSeconds)}{" "}
                      {getStatusIcon(msg.status)}
                    </div>
                  </div>
                </div>
              );
            })}
          <div ref={chatEndRefElement}></div>
          {isMms && messageStore.phoneFrameSubject && (
            <div className={styles.msgText}>
              <div className={styles.userTextCover}>
                <p className="pe-4 words-dont-break-out">{`<Subject: ${messageStore.phoneFrameSubject}>`}</p>
              </div>
            </div>
          )}

          {isMms && phoneFrameImageUrl ? (
            <div className={styles.mmsImage}>
              {squareSpaceBtn && <DeleteFilled className="text-danger me-2" onClick={handleRemoveFile} />}
              {imageSupportList.includes(messageStore.mediaType) ? (
                <img
                  style={{ maxWidth: "180px" }}
                  // src={URL.createObjectURL(phoneFrameImageUrl)}
                  src={IsBlob(phoneFrameImageUrl)}
                  alt={"media"}
                />
              ) : videoSupportList.includes(messageStore.mediaType) ? (
                <video
                  style={{ maxWidth: "180px" }}
                  // src={URL.createObjectURL(phoneFrameImageUrl)}
                  src={IsBlob(phoneFrameImageUrl)}
                  controls
                />
              ) : audioSupportList.includes(messageStore.mediaType) ? (
                <div className="text-end">
                  <i class="far fa-file-audio fs-1 text-info-color p-4 rounded-3 mb-2" style={{ background: "#f7f7f7" }}></i>

                  <audio
                    style={{ maxWidth: "180px" }}
                    controls
                    // src={URL.createObjectURL(phoneFrameImageUrl)}
                    src={IsBlob(phoneFrameImageUrl)}
                  />
                </div>
              ) : (
                <div style={{ maxWidth: "180px", background: "#149cbe30" }} className="rounded p-4">
                  <FilePdfOutlined className="fs-1 text-info-color" />
                </div>
              )}
            </div>
          ) : (
            messageStore.phoneFrameImageFileUrl && (
              <div className={styles.mmsImage}>
                {squareSpaceBtn && <DeleteFilled className="text-danger me-2" onClick={handleRemoveFile} />}
                <div style={{ maxWidth: "180px", background: "#149cbe30" }} className="rounded p-4" onClick={() => setViewMedia(true)} type="button">
                  <FileOutlined className="fs-1 text-info-color" />
                </div>
              </div>
            )
          )}
          {messageStore.phoneFrameCurrentMessage && (
            <div className={styles.msgText}>
              <div className={styles.userTextCover}>
                <p className="pe-4 words-dont-break-out">{messageStore.phoneFrameCurrentMessage}</p>
              </div>
            </div>
          )}
        </div>
      </Col>
      {showMessageBox && (
        <Col span={24}>
          <Formik
            initialValues={initialValues}
            validationSchema={MessageSchema}
            innerRef={formikRef}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              let tempMessage = compileMessageForLength({
                message: values?.message,
                replyLink: values?.replyLink
              });
              let totalCharacterCount = caculateMessageLengthWithLineBreaks(tempMessage);
              let totalMessagesCount = checkTotalMessageCount(totalCharacterCount, checkIsEnglish(values?.message));
              if (totalMessagesCount > authStore?.balanceObj?.maxPossibleCredits.sms) {
                setLowBalanceAlert(true);
                setSubmitting(false);
                return;
              }
              handleSend(values).finally(() => {
                setSubmitting(false);
                setInvoiceLink();
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} className="mt-auto px-3 border-top rounded-bottom">
                  <Row type="flex" align="middle" justify="start" className="py-2" gutter={[10, 10]}>
                    {squareSpaceBtn && (
                      <Col span={24}>
                        <MessageType />
                      </Col>
                    )}
                    <Col span={24}>
                      <SenderMenu secondary isMms={isMms} errors={errors} touched={touched} values={values} isQuick={true} label={false} Link={false} size="default" setFieldValue={setFieldValue} />
                    </Col>
                    <MessageContainer
                      size="small"
                      label={false}
                      retailChatInvoice={searchedContact?.email}
                      formikRef={formikRef}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                      testSMS={!user.quickApproved}
                      name={"message"}
                      scheduler={!user.quickApproved}
                      quickMessage
                      isMms={isMms}
                      subject={false}
                      smallSize={smallSize}
                      minRows={2}
                      handleSubmit={handleSubmit}
                      endProp={<AppButton label="Send" type="submit" className="rouned-xl" light size="default" />}
                    />
                  </Row>
                  <AlertModal
                    visible={lowBalanceAlert}
                    title="Low Balance"
                    description="You don't have enough credits to send this message. Please add credits to continue."
                    onCancel={() => setLowBalanceAlert(false)}
                    primaryBtnProps={{
                      label: "Add Credits",
                      onClick: () => {
                        setLowBalanceAlert(false);
                        modalStore.toggleModal("showQuickSMSModal", false);
                        history.push("/buy-credits", {
                          from: location.pathname
                        });
                      }
                    }}
                    secondaryBtnProps={{
                      label: "Cancel",
                      onClick: () => setLowBalanceAlert(false)
                    }}
                    secondaryBtn
                    closable
                    error
                  />
                </Form>
              );
            }}
          </Formik>
        </Col>
      )}
      <ViewMedia isVisible={viewMedia} handleCancel={() => setViewMedia(false)} fileurl={messageStore.phoneFrameImageFileUrl} getUploadedDocument={authStore.getUserDocument} />
    </Row>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore,
  templateStore: stores.store.templateStore,
  authStore: stores.store.authStore,
  integrationStore: stores.store.integrationStore
}))(observer(Chat));
