import { get, post, put, deleteRequest } from "utils/api";
import queryString from "query-string";

/**
 * Call get gateway sms stats api for admin
 * @returns {Promise<any>}
 */
export const getGatewaySmsStats = async params => {
  const gatewayMessagesStatsResponse = await get(`/admin/gateway/stats`, params);
  return gatewayMessagesStatsResponse.data;
};

/**
 * Call get filtered gateway sms api for admin
 * @returns {Promise<any>}
 */
export const getFilteredGatewaySMS = async params => {
  const filteredGatewayMessagesResponse = await get(`/admin/gateway/filter`, params);
  return filteredGatewayMessagesResponse.data;
};

export const getConversionMessages = async params => {
  // save api response for Bulk List list
  const gatewayListResponse = await get("/gateway/conversation", params);
  return gatewayListResponse.data.data;
};

/**
 * Call get all bulk api
 * @returns {Promise<any>}
 */
export const getAllGatewaySMS = async params => {
  // save api response for Bulk List list
  const gatewayListResponse = await get("/gateway", params);
  return gatewayListResponse.data.data;
};

/**
 * Call get all retail chat api
 * @returns {Promise<any>}
 */
export const getAllRetailChat = async params => {
  const gatewayListResponse = await get("/gateway/squarespace-sms", params);
  return gatewayListResponse.data.data;
};

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */
export const addGatewaySMS = async (gatewaySMSData = {}) => {
  const addGatewaySMSResponse = await post(`/gateway`, gatewaySMSData);
  return addGatewaySMSResponse.data;
};

//MMS
// export const addGatewayMMS = async (data = {}) => {
//   const addGatewaySMSResponse = await post(`/gateway/mms`, data);
//   return addGatewaySMSResponse.data.data;
// };

//GATEWAY BULK APIS

/**
 * Call get all bulk api
 * @returns {Promise<any>}
 */
export const getAllBulks = async params => {
  const BulkListResponse = await get("/gateway/bulk", params);
  return BulkListResponse.data.data;
};

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */
export const addBulk = async (bulkData = {}, mode = "") => {
  const addGatewayResponse = await post(`/gateway/bulk?mode=${mode}`, bulkData);
  return addGatewayResponse.data;
};

export const editBulk = async (editData = {}) => {
  const editGatewayResponse = await post(`/gateway/editschdulebulk`, editData);
  console.log(editGatewayResponse);

  return editGatewayResponse.data;
};

/**
 * Add new bulk list api for Multiple groups
 * @param bulkData
 * @returns {Promise<any>}
 */
export const addBulkForMultiGroup = async (bulkData = {}) => {
  const addGatewayResponse = await post(`/gateway/multigroup`, bulkData);
  return addGatewayResponse.data;
};

export const addBulkByList = async (bulkData = {}, mode = "") => {
  const addGatewayResponse = await post(`/gateway/bulk-number-list?mode=${mode}`, bulkData);
  return addGatewayResponse.data;
};

export const sendBatchMessage = async data => {
  const response = await post("/gateway/excelbulksms", data, true, {
    "Content-Type": "multipart/form-data"
  });
  return response.data;
};

export const getBulkDetails = async (bulkData = {}) => {
  const getGatewayBulkDetailsResponse = await get(`/gateway/bulkdetails/${bulkData._id}`);
  return getGatewayBulkDetailsResponse.data.data;
};

export const getBulkDetailsById = async id => {
  const getGatewayBulkDetailsResponse = await get(`/gateway/bulk/${id}`);
  return getGatewayBulkDetailsResponse.data.data;
};

export const resendFailedBulk = async (failedBulkId = {}) => {
  const resendGatewayFailedBulkResponse = await get(`/gateway/bulk/resend/${failedBulkId}`);
  return resendGatewayFailedBulkResponse.data;
};

export const getExportBulkLogList = async params => {
  const exportBulkLogListResponse = await get(`/gateway/export`, params);
  return exportBulkLogListResponse.data;
};

/*
New Inbox API
*/

export const getRetailChatInboxList = async params => {
  const BulkListResponse = await get("/gateway/get-square-space-chat", params);
  return BulkListResponse.data.data;
};

export const getAllInboxList = async params => {
  const BulkListResponse = await get("/gateway/inbox-quick-sms", params);
  return BulkListResponse.data.data;
};

export const getAllInboxFilterListOld = async args => {
  const InboxCampaignResponse = await get(`/gateway/inbox-filter-sms/${args}`);
  // const InboxCampaignResponse = await get(`/gateway/inbox-quick-sms/`, args);
  return InboxCampaignResponse.data.data;
};

export const getAllInboxFilterList = async args => {
  // const InboxCampaignResponse = await get(`/gateway/inbox-filter-sms/${args}`);
  const InboxCampaignResponse = await get(`/gateway/inbox-quick-sms/`, args);
  return InboxCampaignResponse.data.data;
};
// Rename Bulk
export const renameBulk = async (Id, name) => {
  const changeBulkName = await put(`/gateway/updatebulk/${Id}`, name);
  return changeBulkName.data;
};

// Get By Status

export const getBulkByStatus = async (key, params) => {
  const { page } = params;
  const addResponse = await get(`/gateway/bulk?status=${key}&page=${page}`);
  return addResponse.data;
};

/**
 * Draft bulk api SMS And MMS
 * @returns {Promise<any>}
 */
// get all saved bulk api
export const getAllSavedBulks = async params => {
  const BulkListResponse = await get("/draftcampaign", params);
  return BulkListResponse.data.data;
};
// save bulk data api
export const saveBulkData = async (data = {}) => {
  const BulkResponse = await post("/draftcampaign", data);
  return BulkResponse.data.data;
};
// get saved bulk by id api
export const getSavedBulkById = async (id = "") => {
  const BulkResponse = await get(`/draftcampaign/campaign/${id}`);
  return BulkResponse.data.data;
};

// update saved bulk api
export const updateSavedBulk = async (id = "", data = {}) => {
  const BulkResponse = await put(`/draftcampaign/update-campaign/${id}`, data);
  return BulkResponse.data;
};

// delete saved bulk api
export const deleteSavedBulk = async (id = "") => {
  const BulkResponse = await deleteRequest(`/draftcampaign/campaign/${id}`);
  return BulkResponse.data;
};

// get bulk messages details by id api
export const getBulkMessages = async (id = "", params) => {
  const BulkResponse = await get(`/gateway/campaign/${id}`, params);
  return BulkResponse.data;
};

// get bulk short url analytics by id api
export const getBulkShortUrlAnalytics = async (id = "", params) => {
  const BulkResponse = await get(`/gateway/analytic/${id}`, params);
  return BulkResponse.data;
};

// campaign appoval apis - admin
export const getBulkListForApproval = async params => {
  const BulkListResponse = await get("/admin/approval-list", params);
  return BulkListResponse.data.data;
};

export const getBulkDetailsByIdForApproval = async id => {
  const getGatewayBulkDetailsResponse = await get(`/admin/approval-sms/${id}`);
  return getGatewayBulkDetailsResponse.data.data;
};

export const approveBulk = async (id, data) => {
  const approveBulkResponse = await put(`/admin/admin-approval/${id}`, data);
  return approveBulkResponse.data;
};

// campaign appoval apis - user
export const getBulkListForApprovalOfUser = async params => {
  const BulkListResponse = await get("/gateway/approval-message", params);
  return BulkListResponse.data.data;
};

export const getBulkDetailsByIdForApprovalOfUser = async id => {
  const getGatewayBulkDetailsResponse = await get(`/gateway/approval-message/${id}`);
  return getGatewayBulkDetailsResponse.data;
};

export const getAllBulksIncludingApproval = async params => {
  const BulkListResponse = await get("/gateway/all-bulk-approval", params);
  return BulkListResponse.data.data;
};

// Schedule SMS List Bulk
export const getScheduledSMSBulkList = async params => {
  const response = await get("/scheduler/messageSchdule", params);
  return response.data;
};

// update schedule time
export const updateScheduleBulk = async data => {
  const response = await put(`/scheduler/editmessageSchdule`, data);
  return response.data;
};

// get cost of campaign api
//  @param {Object} totalSMS, totalMMS
export const getCostOfCampaign = async params => {
  const response = await get(`/purchase-order/current`, params);
  return response.data;
};

export const isAnalyticsDataAvailable = async params => {
  const response = await get(`/user/show-analytics-dashboard`, params);
  return response.data;
};

// cancel schedule bulk api
export const cancelScheduleBulk = async id => {
  const response = await put(`/scheduler/cancelSchdule`, id);
  return response.data;
};

// get approval or pending bulk details by id api
export const getCampaignDetails = async id => {
  const getCampaignResponse = await get(`/gateway/bulkById/${id}`);
  return getCampaignResponse.data;
};

// Admin quick message pending list api
export const getQuickMessagePendingList = async params => {
  const response = await get("/admin/approval-sms-list", params);
  return response.data;
};

// reschedule message api
export const rescheduleMessage = async (id, data) => {
  const response = await put(`/scheduler/reschedulequickschedule/${id}`, data);
  return response.data;
};

// cancel schedule message api
export const cancelScheduleMessage = async id => {
  const response = await put(`/scheduler/cancelquickschedule/${id}`);
  return response.data;
};

// export clicks analytics
export const exportClicksAnalytics = async params => {
  const response = await get(`/gateway/getClickedData`, params);
  return response.data;
};

export const getOptoutList = async (id, params) => {
  const response = await get(`/gateway/optout/${id}`, params);
  return response.data.data;
};

export const cancelSchedule = async params => {
  const response = await put("/scheduler/cancelRecurranceSchdule", params);

  return response.data;
};
