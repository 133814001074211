import { get, post, put, deleteRequest } from "utils/api";

// Add Personal Number and Recieve OTP (user)
export const addPersonalNumber = async values => {
  const response = await post("/user/send-otp-personal-senders", values);
  return response.data;
};

// Verify Personal Number (user)
export const verifyPersonalNumber = async (id, values) => {
  const response = await post(`/user/verify-otp-personal-senders/${id}`, values);
  return response.data;
};

// Get New OTP (user)
export const getVerifyOTP = async params => {
  const response = await post("/user/resend-otp-personal-senders", params);
  return response.data;
};

// Get All Personal Numbers (user)
export const getPersonalNumbers = async params => {
  const response = await get("/user/get-personal-senders", params);
  return response.data;
};

// Delete Personal Number (user)
export const deletePersonalNumber = async id => {
  const response = await deleteRequest(`/user/remove-personal-senders/${id}`);
  return response.data;
};

// add reference to a personal number (user)
export const addReference = async (id,selectedNum, values) => {
  const response = await post(`/user/edit-reference-personal-number/${id}?phone=${selectedNum}`, values);
  return response.data;
};

// Get All Personal Numbers For Verification (admin)
export const getPersonalNumbersAdmin = async params => {
  const response = await get("/admin/get-personal-sender-verification", params);
  return response.data.data;
};

// Approve or Reject Personal Number (admin)
export const approveOrRejectPersonalNumber = async (id, values) => {
  const response = await post(`/admin/approve-reject-personal-sender-verification/${id}`, values);
  return response.data;
};

// delete personal number (admin)
export const deletePersonalNumberAdmin = async id => {
  const response = await deleteRequest(`/admin/delete-personal-admin/${id}`);
  return response.data;
};
