import React, { useState } from "react";
import moment from "moment";
import { Col, Row, Typography, Tag, Tooltip, Divider, Table } from "antd";
import { dateTimeFormat, dateTimeFormatWithSeconds } from "constant/constant";
import AppButton from "components/AppButton/AppButton";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { messageStatus } from "constant/commonList";
import { inject, observer } from "mobx-react";
import { event } from "react-ga";
import { toJS } from "mobx";
import AlertModal from "components/Modals/AlertModal";

const CampaignDetails = (props) => {
  const {
    messageContent = false,
    campaignData,
    handleViewContent,
    gatewayBulkStore,
    setTabName,
    refreshCampaignData,
  } = props;
  const groupURL =
    campaignData?.group && campaignData?.group._id
      ? campaignData?.group._id
      : campaignData?.followUpGroup && campaignData?.followUpGroup?._id;

  const [cancelRecurrenceAlert, setCancelRecurrenceAlert] = useState(false);
  const [cancelRecurrenceSuccess, setCancelRecurrenceSuccess] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const getScheduleTypeInfo = (data) => {
    const getEndInfo = (data) => {
      if (data.end_date) {
        return `until ${moment(data.end_date).format("DD MMM YYYY")}`;
      }
      return "no end date";
    };

    if (!data.repeat || data.repeat === "one_time") {
      return {
        icon: "calendar-day",
        text: "One Time",
        description: `Scheduled for ${moment(data.scheduleAt).format(
          dateTimeFormatWithSeconds
        )}`,
        endInfo: null,
      };
    }

    switch (data.repeat) {
      case "daily":
        return {
          icon: "calendar-day",
          text: "Daily",
          description: `Every ${data.every || 1} day(s) starting at ${moment(
            data.start_date
          ).format(dateTimeFormat)}`,
          endInfo: getEndInfo(data),
        };
      case "weekly":
        return {
          icon: "calendar-week",
          text: "Weekly",
          description: `Every ${
            data.every || 1
          } week(s)${
            data.daysOfWeek ? ` on ${data.daysOfWeek.join(", ")}` : ""
          } starting at  ${moment(data.start_date).format(dateTimeFormat)}`,
          endInfo: getEndInfo(data),
        };
      case "monthly":
        return {
          icon: "calendar-alt",
          text: "Monthly",
          description: `Every ${data.every || 1} month(s) on day ${
            data.dayOfMonth || "1"
          } starting at ${moment(data.start_date).format(dateTimeFormat)}`,
          endInfo: getEndInfo(data),
        };
      default:
        return {
          icon: "calendar",
          text: data.repeat,
          description: "",
          endInfo: getEndInfo(data),
        };
    }
  };

  const scheduleInfo = getScheduleTypeInfo(campaignData);

  const getStatusColor = (status) => {
    const statusObj = messageStatus.find((s) => s.value === status);
    return statusObj?.color || "fff";
  };

  const queueColumns = [
    {
      title: "Occurrence Date",
      dataIndex: "occurrenceDate",
      key: "occurrenceDate",
      width: "60%",
      render: (text) => (
        <div className="d-flex align-items-center">
          <i className="fas fa-calendar-alt text-info-color mr-2" />
          <span>{moment(text).format(dateTimeFormatWithSeconds)}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "queuestatus",
      key: "queuestatus",
      width: "40%",
      align: "center",
      render: (status, record) => (
        <>
          <Tag
            color={getStatusColor(status)}
            style={{
              minWidth: "100px",
              textAlign: "center",
              textTransform: "capitalize",
              fontSize: "12px",
              padding: "4px 12px",
              borderRadius: "12px",
              color: status === "scheduled" ? "#000000" : undefined,
            }}
          >
            <i
              className={`fas fa-${
                status === "complete"
                  ? "check-circle"
                  : status === "failed"
                  ? "circle-exclamation"
                  : "clock"
              } me-2`}
            />
            {messageStatus.find((s) => s.value === status)?.label || status}
          </Tag>
          {record?.info && (
            <Tooltip title={record?.info}>
              <i className="fa-solid fa-circle-info text-danger ml-2" />
            </Tooltip>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: "20%",
      align: "center",
      render: (_, record) =>
        record.queuestatus === "scheduled" && (
          <Tooltip title="Cancel This Occurrence">
            <i
              className="fas fa-ban text-danger"
              style={{
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => {
                setCancelRecurrenceAlert(true);
                setSelectedRecord(record);
              }}
            />
          </Tooltip>
        ),
    },
  ];

  return (
    <Row type="flex" align="middle" gutter={[20, 20]} className="pt-3 pt-md-4">
      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Campaign Name
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          ellipsis
          className="font-weight-bold m-0 fs-6 text-info-color"
        >
          {campaignData?.name}
        </Typography.Title>
      </Col>

      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Send Time
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          ellipsis
          className="font-weight-bold m-0 fs-6 text-info-color"
        >
          {moment(
            campaignData?.scheduleAt
              ? campaignData?.scheduleAt
              : campaignData?.createdAt
          ).format(dateTimeFormatWithSeconds)}
        </Typography.Title>
      </Col>

      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Schedule Type
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          ellipsis
          className="font-weight-bold m-0 fs-6 text-info-color"
        >
          {scheduleInfo.text}
        </Typography.Title>
      </Col>

      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Schedule Details
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 fs-6 text-info-color"
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            lineHeight: "1.5",
          }}
        >
          {scheduleInfo.description}
          {scheduleInfo.endInfo && <span className="ml-1">{scheduleInfo.endInfo}</span>}
        </Typography.Title>
      </Col>

      {campaignData?.queueDetail?.length > 0 && (
        <>
          <Col span={24}>
            <Divider orientation="center">
              <Typography.Title
                level={4}
                className="font-weight-bold m-0 text-capitalize fs-6"
              >
                Schedule Occurrences
              </Typography.Title>
            </Divider>
          </Col>
          <Col span={24}>
            <div className="mb-4 d-flex justify-content-center">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={8}>
                  <div className="stat-card text-center">
                    <Typography.Text type="secondary">Completed</Typography.Text>
                    <Typography.Title
                      level={4}
                      style={{
                        color: messageStatus.find((s) => s.value === "complete")?.color,
                      }}
                    >
                      {
                        campaignData.queueDetail.filter(
                          (q) => q.queuestatus === "complete"
                        ).length
                      }
                    </Typography.Title>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="stat-card text-center">
                    <Typography.Text type="secondary">Scheduled</Typography.Text>
                    <Typography.Title level={4} className="text-info-color">
                      {
                        campaignData.queueDetail.filter(
                          (q) => q.queuestatus === "scheduled"
                        ).length
                      }
                    </Typography.Title>
                  </div>
                </Col>
              </Row>
            </div>
            <Table
              columns={queueColumns}
              dataSource={campaignData.queueDetail}
              rowKey="_id"
              size="middle"
              pagination={false}
              scroll={{ y: 300 }}
              className="occurrences-table"
            />
          </Col>
        </>
      )}

      {campaignData?.runCount !== undefined && (
        <>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title
              level={4}
              className="font-weight-bold m-0 text-capitalize fs-6"
            >
              Run Count
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <Typography.Title
              level={4}
              ellipsis
              className="font-weight-bold m-0 fs-6 text-info-color"
            >
              {campaignData.runCount}
            </Typography.Title>
          </Col>
        </>
      )}

      {campaignData?.nextOccurrenceDate && (
        <>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title
              level={4}
              className="font-weight-bold m-0 text-capitalize fs-6"
            >
              Next Occurrence
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <Typography.Title
              level={4}
              ellipsis
              className="font-weight-bold m-0 fs-6 text-info-color"
            >
              {moment(campaignData.nextOccurrenceDate).format(dateTimeFormatWithSeconds)}
            </Typography.Title>
          </Col>
        </>
      )}

      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Sender
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          ellipsis
          className="font-weight-bold m-0 fs-6 text-info-color"
        >
          {campaignData?.sender}
        </Typography.Title>
      </Col>

      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Group {!isEmpty(campaignData?.multigroup) ? "used" : ""}
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        {campaignData?.group ? (
          <Link to={groupURL ? `/group/${groupURL}` : "#"}>
            <Typography.Title
              level={4}
              ellipsis
              className="font-weight-bold m-0 fs-6 text-info-color text-hover-underline"
            >
              {groupURL
                ? campaignData?.group && campaignData?.group._id
                  ? campaignData?.group.name
                  : campaignData?.followUpGroup
                  ? campaignData?.followUpGroup?.name
                  : "Group Removed"
                : ""}
            </Typography.Title>
          </Link>
        ) : campaignData?.multigroup ? (
          <Typography.Title
            level={4}
            ellipsis
            className="font-weight-bold m-0 fs-6 text-info-color"
          >
            {campaignData?.multigroup?.length}
          </Typography.Title>
        ) : (
          ""
        )}
      </Col>

      <Col xl={6} lg={8} md={10} span={24}>
        <Typography.Title
          level={4}
          className="font-weight-bold m-0 text-capitalize fs-6"
        >
          Total Recipients
        </Typography.Title>
      </Col>
      <Col xl={18} lg={16} md={14} span={24}>
        <Typography.Title
          level={4}
          ellipsis
          className="font-weight-bold m-0 fs-6 text-info-color"
        >
          {campaignData?.recipients
            ? campaignData?.recipients
            : campaignData?.group && campaignData?.group._id
            ? campaignData?.group.totalContacts
            : campaignData?.followUpGroup
            ? campaignData?.followUpGroup?.totalContacts
            : "N/A"}
        </Typography.Title>
      </Col>

      {campaignData?.longUrl && (
        <>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title
              level={4}
              className="font-weight-bold m-0 text-capitalize fs-6"
            >
              Long URL
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <a href={campaignData?.longUrl} target="_blank" rel="noreferrer">
              <Typography.Title
                level={4}
                ellipsis
                className="font-weight-bold m-0 fs-6 text-info-color text-hover-underline"
              >
                {campaignData?.longUrl}
              </Typography.Title>
            </a>
          </Col>
        </>
      )}

      {messageContent && (
        <>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title
              level={4}
              className="font-weight-bold m-0 text-capitalize fs-6"
            >
              Message Content
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <AppButton
              label={"View Content"}
              light
              withoutBg
              onClick={handleViewContent}
            />
          </Col>
        </>
      )}

      {campaignData?.hasFollowUp && (
        <>
          <Col span={24}>
            <Divider />
          </Col>
          {campaignData?.followUpStatus && (
            <>
              <Col xl={6} lg={8} md={10} span={24}>
                <Typography.Title
                  level={4}
                  className="font-weight-bold m-0 text-capitalize fs-6"
                >
                  Status
                </Typography.Title>
              </Col>
              <Col xl={18} lg={16} md={14} span={24}>
                {campaignData?.followUpStatus === "failed" ? (
                  <Tooltip
                    placement="left"
                    title={
                      <>
                        Sorry, we couldn't send this follow-up campaign!
                        <Divider className="my-2" />
                        REASON: {campaignData?.followUpReason}
                      </>
                    }
                    trigger="hover"
                  >
                    <div className="d-flex flex-row align-items-center">
                      <Tag color="red">
                        {campaignData?.followUpStatus?.toUpperCase()}
                      </Tag>
                      <i className="fas fa-info-circle text-danger-color ml-2" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tag
                    color={
                      campaignData?.followUpStatus === "queued" ? "volcano" : "cyan"
                    }
                  >
                    {campaignData?.followUpStatus?.toUpperCase()}
                  </Tag>
                )}
              </Col>
            </>
          )}
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title
              level={4}
              className="font-weight-bold m-0 text-capitalize fs-6"
            >
              Follow Up Time
            </Typography.Title>
          </Col>
          <Col xl={18} lg={16} md={14} span={24}>
            <Typography.Title
              level={4}
              ellipsis
              className="font-weight-bold m-0 fs-6 text-info-color"
            >
              {moment(campaignData?.followUpTime).format(dateTimeFormatWithSeconds)}
            </Typography.Title>
          </Col>
          <Col xl={6} lg={8} md={10} span={24}>
            <Typography.Title
              level={4}
              className="font-weight-bold m-0 text-capitalize fs-6"
            >
              Follow Up Message
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Title
              level={4}
              ellipsis
              className="font-weight-bold m-0 fs-6 text-info-color"
            >
              {campaignData?.followUpMessage}
            </Typography.Title>
          </Col>
        </>
      )}

      <>
        <AlertModal
          visible={cancelRecurrenceAlert}
          title="Cancel Recurrence Schedule"
          description="Are you sure you want to cancel this recurrence schedule?"
          warning
          error
          closable
          primaryBtnProps={{
            label: "Cancel",
            onClick: async () => {
              try {
                setCancelLoading(true);
                const res = await gatewayBulkStore.cancelRecurranceSchedule({
                  scduleid: campaignData?._id,
                  queueid: selectedRecord?._id,
                });

                if (res.status) {
                  setCancelRecurrenceAlert(false);
                  if (refreshCampaignData) {
                    await refreshCampaignData();
                  }
                  setCancelRecurrenceSuccess(true);
                }
              } catch (error) {
                console.error("Error cancelling recurrence:", error);
              } finally {
                setCancelLoading(false);
              }
            },
          }}
          onCancel={() => {
            setCancelRecurrenceAlert(false);
          }}
          loading={cancelLoading}
        />

        <AlertModal
          visible={cancelRecurrenceSuccess}
          title="Schedule Cancelled!"
          description="Recurrence schedule has been cancelled successfully."
          primaryBtnProps={{
            label: "Ok",
            onClick: () => {
              setCancelRecurrenceSuccess(false);
            },
          }}
          closable
          onCancel={() => setCancelRecurrenceSuccess(false)}
        />
      </>
    </Row>
  );
};

export default inject((stores) => ({
  gatewayBulkStore: stores.store.gatewayBulkStore,
}))(observer(CampaignDetails));
