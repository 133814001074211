// Set base url for all api
export const PUBLIC_URL = process.env.PUBLIC_URL;
// export const API_SECURITY_KEY = process.env.API_SECURITY_KEY;
export const API_SECURITY_KEY = undefined;

export const BASE_URL = process.env.REACT_APP_BASE_URL + (API_SECURITY_KEY ? "/web" : "");
// export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
// export const BASE_URL = "http://localhost:4110/api/v1";
// export const SOCKET_URL = "http://localhost:4110";
// export const BASE_URL = "https://apit.gunisms.com.au/api/v1";
// export const SOCKET_URL = "https://apit.gunisms.com.au";
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const MQTT_URL = process.env.REACT_APP_SOCKET_URL;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID;
export const APPLE_CLIENT_ID = process.env.APPLE_CLIENT_ID;
export const APPLE_REDIRECT_URI = process.env.APPLE_REDIRECT_URI;
export const PHONE_UPDATE = "phoneUpdate";
export const MESSAGE_NEW = "newMessage";
export const ROOM_NEW = "newRoom";
export const USER_UPDATE = "userUpdate";
export const GATEWAY_MESSAGE_STATUS_UPDATE = "gatewayMessageUpdateStatus";
export const GATEWAY_MMS_STATUS_UPDATE = "gatewayMMSUpdateStatus";
export const GATEWAY_MESSAGE_CREATE = "gatewayMessageCreate";
export const GATEWAY_MMS_CREATE = "gatewayMMSCreate";
export const GROUP_PARTICIPANTS_UPDATE = "groupParticipantsUpdate";
export const GROUP_STATUS_UPDATE = "groupStatusUpdate";
export const USER_BALANCE_UPDATE = "userBalanceUpdate";
export const GATEWAY_MESSAGE_DELETE = "gatewayMessageDelete";
export const GATEWAY_MMS_DELETE = "gatewayMMSDelete";
export const GATWAY_REPLY = "newReplyMessage";
