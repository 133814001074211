import { runInAction, decorate, observable, toJS, action } from "mobx";
import {
  getAllGatewaySMS,
  addGatewaySMS,
  getAllInboxList,
  getAllInboxFilterList,
  getExportBulkLogList,
  rescheduleMessage,
  cancelScheduleMessage,
  getRetailChatInboxList,
  getAllInboxFilterListOld,
  addBulkForMultiGroup
} from "service/gatewayService";

class ObservableGatewayStore {
  gatewaySMSList = [];
  addGatewaySMSResponse = undefined;
  total = 0;
  pageSize = 10;
  current = 1;
  search = undefined;
  sortField = "createdAt";
  sortOrder = "descend";
  showNewCreateMessageAlert = false;
  gatewaySMSBulkList = [];
  exportLogList = [];
  response = undefined;

  // using for inbox messaging
  listSize = 10; // pageSize
  listItem = 1; // current
  totalItems = 0; // total
  listLenght = 0; // totalPages
  hasNextPage = false;

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  setListItem = currentItem => {
    runInAction(() => {
      this.listItem = currentItem || 1;
    });
  };

  /**
   * get all Users
   * @returns {Promise<[]>}
   */
  getAllGatewaySMS = async args => {
    // call & save User list response
    const params = {
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }
    const getAllGatewaySMSResponse = await getAllGatewaySMS(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getAllGatewaySMSResponse;
        this.gatewaySMSList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.gatewaySMSList;
  };

  /**
   * add new User
   * @param userData
   * @returns {Promise<*>}
   */
  addGatewaySMS = async gatewaySMSData => {
    // call api for add new User
    const method = gatewaySMSData?.group_array ? addBulkForMultiGroup : addGatewaySMS;
    const addGatewaySMSResponse = await method(gatewaySMSData);
    try {
      runInAction(() => {
        this.addGatewaySMSResponse = addGatewaySMSResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGatewaySMSResponse = error;
      });
    }
    return this.addGatewaySMSResponse;
  };

  // addGatewayMMS = async data => {
  //   // call api for add new User
  //   const addGatewaySMSResponse = await addGatewayMMS(data);
  //   try {
  //     runInAction(() => {
  //       this.addGatewaySMSResponse = addGatewaySMSResponse;
  //     });
  //     this.getAllGatewaySMS({ isMMS: true });
  //   } catch (error) {
  //     runInAction(() => {
  //       this.addGatewaySMSResponse = error;
  //     });
  //   }
  //   return this.addGatewaySMSResponse;
  // };

  updateMessageStatus = async statusObj => {
    try {
      runInAction(() => {
        const findGatewayMessageIndex = this.gatewaySMSList.findIndex(x => x._id === statusObj._id);
        if (findGatewayMessageIndex > -1) {
          const tempMessageObj = {
            ...this.gatewaySMSList[findGatewayMessageIndex]
          };
          tempMessageObj.status = statusObj.status;
          this.gatewaySMSList.slice(findGatewayMessageIndex, 1);
          const tempGatewaySMSList = [...this.gatewaySMSList];
          tempGatewaySMSList[findGatewayMessageIndex].status = statusObj.status;
          this.gatewaySMSList = tempGatewaySMSList;
        } else if (this.current === 1 && this.search === undefined) {
          //for received sms,put then in first
          statusObj.createdAt = new Date(statusObj.createdAt).toLocaleString();
          this.gatewaySMSList = [statusObj, ...this.gatewaySMSList];
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteMessageStatus = async data => {
    try {
      runInAction(() => {
        const filteredMessageList = this.gatewaySMSList.filter(item => item._id !== data._id);
        this.gatewaySMSList = filteredMessageList;
      });
    } catch (error) {
      console.log(error);
    }
  };

  addMessageToGatewayList = async messageObj => {
    const insertMessageObj = {
      ...messageObj,
      createdAt: new Date(messageObj.createdAt).toLocaleString()
    };
    try {
      runInAction(() => {
        const tempMessageList = [...this.gatewaySMSList];
        if (tempMessageList.length >= 50) {
          tempMessageList.pop();
        }
        this.gatewaySMSList = [insertMessageObj].concat(tempMessageList);
      });
    } catch (error) {
      console.log(error);
    }
    return this.gatewaySMSList;
  };

  setshowNewCreateMessageAlert = () => {
    runInAction(() => {
      this.showNewCreateMessageAlert = !this.showNewCreateMessageAlert;
    });
  };

  newMessageEvent = messageObj => {
    try {
      runInAction(() => {
        const filteredArr = this.gatewaySMSBulkList.filter(_ => _.receiver !== messageObj?.listObj?.receiver);
        this.gatewaySMSBulkList = [messageObj?.listObj, ...filteredArr];
      });
    } catch (error) {
      console.log(error);
    }
  };

  readMessage = data => {
    runInAction(() => {
      if (data?.unread) this.gatewaySMSBulkList.find(item => item.message_id === data?.message_id).unread = false;
    });
  };

  /*
   *Gateway SMS List
   */
  getAllInboxList = async args => {
    const method = args?.retailChat ? getRetailChatInboxList : getAllInboxList;

    // call & save User list response
    const params = {
      page: this.listItem,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }

    params.limit = this.listSize;
    const getAllInboxListResponse = await method(params);
    try {
      runInAction(() => {
        if (args?.retailChat) {
          return (this.gatewaySMSBulkList = getAllInboxListResponse);
        }
        const { items, total, current, limit: pageSize, totalPages, hasNextPage } = getAllInboxListResponse;
        current == 1 ? (this.gatewaySMSBulkList = []) : null;
        this.gatewaySMSBulkList = this.gatewaySMSBulkList ? [...this.gatewaySMSBulkList, ...items] : items;
        // this.pageSize = pageSize;
        // this.current = current;
        // this.total = total;
        // this.totalPages = totalPages;
        // particularly for inbox list
        this.listSize = pageSize;
        this.listItem = current;
        this.totalItems = total;
        this.listLenght = totalPages;
        this.hasNextPage = hasNextPage;

        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        console.log(error);
        this.status = "error";
      });
    }
    return this.gatewaySMSBulkList;
  };

  getAllInboxFilterList = async args => {
    const params = {
      page: this.listItem,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.listSize,
      ...(args && args)
    };

    const getAllInboxFilterListResponse = args?.retailChat ? await getAllInboxFilterListOld(args?.campaignId) : await getAllInboxFilterList(params);
    try {
      runInAction(() => {
        this.gatewaySMSBulkList = getAllInboxFilterListResponse;
        const { items, total, current, limit: pageSize, totalPages, hasNextPage } = getAllInboxFilterListResponse;
        current == 1 ? (this.gatewaySMSBulkList = []) : null;
        this.gatewaySMSBulkList = this.gatewaySMSBulkList ? [...this.gatewaySMSBulkList, ...items] : items;
        // this.pageSize = pageSize;
        // this.current = current;
        // this.total = total;
        // this.totalPages = totalPages;
        // particularly for inbox list
        this.listSize = pageSize;
        this.listItem = current;
        this.totalItems = total;
        this.listLenght = totalPages;
        this.hasNextPage = hasNextPage;
      });
    } catch (error) {
      runInAction(() => {
        console.log(error);
        this.status = "error";
      });
    }
  };

  getExportLogList = async params => {
    const exportLogListResponse = await getExportBulkLogList(params);
    try {
      runInAction(() => {
        this.exportLogList = exportLogListResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    } finally {
      return this.exportLogList;
    }
  };

  rescheduleMessage = async (id, data) => {
    const response = await rescheduleMessage(id, data);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.gatewaySMSList = this.gatewaySMSList.map(item => {
            if (item._id === id) {
              item.scheduleAt = data.scheduleAt;
            }
            return item;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  cancelScheduleMessage = async id => {
    const response = await cancelScheduleMessage(id);
    try {
      runInAction(() => {
        this.response = response;
        console.log(response);
        if (response.status) {
          this.gatewaySMSList = this.gatewaySMSList.map(item => {
            if (item._id === id) {
              item.status = "cancel_schedule";
            }
            return item;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };
}

decorate(ObservableGatewayStore, {
  readMessage: action,
  gatewaySMSList: observable,
  addGatewaySMSResponse: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  listSize: observable, // pageSize
  listItem: observable, // current
  totalItems: observable, // total
  listLenght: observable, // totalPages
  hasNextPage: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  showNewCreateMessageAlert: observable,
  gatewaySMSBulkList: observable,
  exportLogList: observable,
  response: observable
});
export default new ObservableGatewayStore();
